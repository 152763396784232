<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="filter.no"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Scope</label>

            <b-form-select
              v-model="filter.scope_code"
              :options="formattedScopeOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Title</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Title"
              v-model="filter.title"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Type</label>

            <b-form-select
              v-model="filter.type_code"
              :options="options.types"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Category</label>

            <b-form-select
              v-model="filter.category_code"
              :options="options.categories"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="getDesigns(true)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="d-flex my-3 justify-content-start">
          <button
            class="btn btn-success btn-md"
            @click="showInputModal(null, 'Form Add Design')"
          >
            <i class="flaticon-add"></i> Add Design
          </button>
        </div>

        <complete-table :loading="loading" :data="designs">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 50px">No</th>
              <th style="min-width: 50px">Design Number</th>

              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Code</span>
              </th>
              <th style="min-width: 100px">Title</th>
              <th style="min-width: 100px">Numbering</th>
              <th style="min-width: 100px">Scope</th>
              <th style="min-width: 100px">Type</th>
              <th style="min-width: 101px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i, number }">
            <tr v-bind:key="i">
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ number }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 text-center font-weight-bolder d-block font-size-lg"
                >
                  {{ item.no }}
                </span>
              </td>
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.numbering }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.scope ? item.scope.name : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.type ? item.type.name : "-" }}
                </span>
              </td>

              <td class="pr-0">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="showInputModal(item, 'Form Update Design', false)"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="showDeleteModal(item)"
                >
                  <i class="flaticon-delete"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>

        <b-modal ref="input" hide-footer :title="title">
          <div class="d-block text-center">
            <form>
              <div class="row">
                <div class="col-md-12 py-2 col-sm-12 text-left">
                  <label for="input-large">Number *</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Number"
                    v-model="form.numbering"
                  ></b-form-input>
                </div>

                <div class="col-md-12 py-2 col-sm-12 text-left">
                  <label for="input-large">Title *</label>

                  <b-form-input
                    id="input-large"
                    size="lg"
                    placeholder="Title"
                    v-model="form.title"
                  ></b-form-input>
                </div>

                <div class="col-md-12 py-2 col-sm-12 text-left">
                  <label for="input-large">Scope *</label>

                  <b-form-select
                    v-model="form.scope"
                    :options="formattedScopeOptions"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-12 py-2 col-sm-12 text-left">
                  <label for="input-large">Type *</label>

                  <b-form-select
                    v-model="form.type"
                    :options="options.types"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-12 py-2 col-sm-12 text-left">
                  <label for="input-large">Category *</label>

                  <b-form-select
                    v-model="form.category"
                    :options="options.categories"
                    size="lg"
                  ></b-form-select>
                </div>

                <div class="col-md-12 py-2 col-sm-12 text-left">
                  <label for="input-large">Numbering Type *</label>

                  <b-form-select
                    v-model="form.numbering_type"
                    :options="options.numberingTypes"
                    size="lg"
                  ></b-form-select>
                </div>
              </div>
            </form>

            <div class="d-flex mt-2">
              <b-button
                :disabled="loading"
                class="btn btn-success mr-2"
                block
                @click="isCreate ? saveDesign() : updateDesign()"
              >
                Save
              </b-button>

              <b-button
                :disabled="loading"
                class="btn btn-secondary mt-0"
                block
                @click="closeInputModal"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-modal>

        <b-modal ref="delete" hide-footer title="Warning">
          <div class="d-block text-center">
            <div class="row">
              <div class="col-md-12 py-2 col-sm-12 text-left">
                <label for="input-large">Are You Sure Want To Delete Design</label>
              </div>
            </div>
            <div class="d-flex mt-2">
              <b-button
                :disabled="loading"
                class="btn btn-success mr-2"
                @click="deleteDesign"
                block
              >
                Delete
              </b-button>

              <b-button
                class="btn btn-secondary mt-0"
                block
                @click="closeDeleteModal"
                :disabled="loading"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import {
  arrayMoreThanOne,
  findOnArray,
  formattedOptions
} from "../../../core/helper/array-validation.helper";
import {
  DELETE_DESIGN,
  GET_DESIGN,
  // GET_DESIGN_BY_DOCTYPE,
  SAVE_DESIGN,
  UPDATE_DESIGN
} from "../../../core/services/store/design.module";
import { GET_SCOPES } from "../../../core/services/store/scope.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import ApiService from "@/core/services/api.service";
import { ternaryObject } from "@/core/helper/object-validation.helper";

export default {
  name: "Design",
  components: { CompleteTable },
  data() {
    return {
      currentItem: null,
      title: null,
      form: {
        numbering: null,
        code: null,
        title: null,
        type: null,
        scope: null,
        numbering_type: null,
        general: null,
        category: null
      },
      filter: {
        no: null,
        scope_code: null,
        title: null,
        type_code: null,
        document_type: null,
        category_code: null
      },
      options: {
        docTypes: [
          {
            value: null,
            text: "Choose Category Type"
          },
          {
            value: "bom",
            text: "BOM"
          }
        ],
        scopes: [
          {
            value: null,
            text: "Choose Scope"
          },
          {
            value: "hmi",
            text: "HMI"
          }
        ],
        categories: [
          {
            value: null,
            text: "Choose Category"
          },
          {
            value: "BOM",
            text: "BOM"
          }
        ],
        numberingTypes: [
          {
            value: null,
            text: "Choose Numbering Type"
          },
          {
            value: "gen",
            text: "gen"
          },
          {
            value: "oto",
            text: "oto"
          }
        ],
        types: [
          {
            value: null,
            text: "Choose Design Type"
          },
          {
            value: "FOR",
            text: "Software"
          },
          {
            value: "DOC",
            text: "Document"
          },
          {
            value: "DRW",
            text: "Drawing"
          }
        ]
      },
      data: {
        designs: [
          {
            number: "GEN-F95-100000",
            title: "BOM Desain Circuit",
            scope: "General",
            type: "Document"
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      loading: state => state.masterDesign.loading,
      designs: state => state.masterDesign.designs?.data,
      scopes: state => state.scope.scopes?.data
    }),

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map(scope => {
          return {
            value: scope.code,
            text: scope.name
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    }
  },
  methods: {
    getDesigns(filter = false) {
      if (!arrayMoreThanOne(this.designs) || filter) {
        this.$store.dispatch(GET_DESIGN, {
          params: this.filter
        });
      }
    },

    getScopes() {
      if (!arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPES, {
          params: {}
        });
      }
    },

    showInputModal(item, title, isCreate = true) {
      console.log(item);
      this.item = item;

      this.title = title;

      this.isCreate = isCreate;

      this.form = {
        code: item?.code ?? null,
        numbering: item?.numbering ?? null,
        title: item?.title ?? null,
        type: item?.type?.code ?? null,
        scope: item?.scope?.code ?? null,
        numbering_type: item?.numbering_type ?? null,
        general: item?.general,
        category: item?.category?.code ?? null
      };

      this.$refs["input"].show();
    },

    closeInputModal() {
      this.$refs["input"].hide();
    },

    showDeleteModal(item) {
      this.currentItem = item;

      this.$refs["delete"].show();
    },

    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    saveDesign() {
      this.$store.dispatch(SAVE_DESIGN, this.formatForm()).then(() => {
        this.closeInputModal();
      });
    },

    updateDesign() {
      this.$store.dispatch(UPDATE_DESIGN, this.formatForm()).then(() => {
        this.closeInputModal();
      });
    },

    deleteDesign() {
      this.$store
        .dispatch(DELETE_DESIGN, {
          code: this.currentItem?.code
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    formatForm() {
      const scope = findOnArray(
        this.formattedScopeOptions,
        "value",
        this.form.scope
      );

      const type = findOnArray(this.options.types, "value", this.form.type);

      const category = findOnArray(
        this.options.categories,
        "value",
        this.form.category
      );

      return {
        ...this.form,
        category: ternaryObject(category, {
          code: category.value,
          name: category.text
        }),
        scope: ternaryObject(scope, {
          name: scope?.text,
          code: scope?.value
        }),
        type: ternaryObject(type, {
          name: type?.text,
          code: type?.value
        }),
        general: this.form.numbering_type === "gen" ? 1 : 0
      };
    },

    getCategories() {
      ApiService.query("category").then(response => {
        this.options.categories = formattedOptions(response.data.data, {
          value: null,
          text: "Choose Category"
        });
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Design" }]);

    this.getDesigns();

    this.getScopes();

    this.getCategories();
  }
};
</script>

<style></style>
